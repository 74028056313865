<template>
  <div class="layout-content">
    <el-row type="flex" justify="space-between">
      <!-- 类别列表 -->
      <el-col :xs="24" :md="4" style="padding: 0px 15px;">
        <div class="category-list">
          <el-row type="flex" :gutter="4">
            <el-col :xs="12" :sm="6" :md="24">
              <el-button style="width: 100%; margin-bottom:10px;" size="small" @click="gotoNoteTag"
                >标签管理</el-button
              >
            </el-col>
            <el-col :xs="12" :sm="6" :md="24" v-for="(item, index) in tagList" :key="index">
              <el-row
                :class="{ category: true, 'category-active': currentTag == item.value }"
                type="flex"
                align="middle"
                @click.native="getBlogByTag(item.value)"
              >
                <span
                  v-if="item.icon"
                  :class="item.icon"
                  :style="{ paddingRight: '10px', color: item.color }"
                ></span>
                <span>{{ item.value }}</span>
                <span style="float: right; position: absolute; right: 10px;">{{ item.count }}</span>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :xs="24" :md="14" style="padding: 0px 15px;">
        <el-row type="flex" style="height: 100%;">
          <template v-for="(blog, index) in blogList">
            <el-col :key="index" style="margin-bottom: 40px;">
              <router-link
                :to="'/note/detail/' + (blog._id || blog.id)"
                tag="div"
                class="blog-list-link"
              >
                <el-row type="flex" align="middle">
                  <el-col :span="24" class="blog-list-title">{{ blog.title || "未命名" }}</el-col>
                  <el-col :span="24" class="blog-short-content">{{ blog.desc }}</el-col>
                </el-row>
              </router-link>
              <el-row type="flex" align="middle" :gutter="20">
                <el-col class="item-bottom-tag">
                  <span class="bc bc-like-fill"></span>
                  <span class="like-count">x {{ blog.likeCount || "0" }}</span>
                </el-col>
                <el-col class="item-bottom-tag view-count">
                  <span class="bc bc-eye"></span>
                  <span style="vertical-align: text-bottom;">{{ blog.viewCount || "0" }}</span>
                </el-col>
                <el-col class="item-bottom-tag">
                  <span class="created-date">{{
                    blog.updated_at && formatDate(blog.updated_at)
                  }}</span>
                </el-col>
              </el-row>
            </el-col>
          </template>
        </el-row>
      </el-col>
      <el-col :xs="24" :md="6" style="padding: 0px 15px;">
        <el-input placeholder="搜索" v-model="search">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button style="margin: 10px 0px; width: 100%;" @click="createBlog">增加文章</el-button>
        <p>最近更新</p>
        <p>热门文章</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import { store } from "../store";

const host = process.env.VUE_APP_HOST_URL;

export default {
  data() {
    return {
      search: "",
      currentTag: "",
      tagList: [],
      categoryList: [
        {
          name: "NodeJS",
          category: "nodejs",
          icon: "bc bc-nodejs",
          color: "#0a3",
          count: 0,
        },
        {
          name: "Golang",
          category: "golang",
          icon: "bc bc-golang",
          color: "#05a",
          count: 0,
        },
        {
          name: "Linux",
          category: "linux",
          icon: "bc bc-linux",
          color: "#333",
          count: 0,
        },
        {
          name: "小程序",
          category: "mp",
          icon: "bc bc-xiaochengxu",
          color: "#0a3",
          count: 0,
        },
        {
          name: "MongoDB",
          category: "mongodb",
          icon: "bc bc-mongodb",
          color: "#0a3",
          count: 0,
        },
        {
          name: "Unity3d",
          category: "unity3d",
          icon: "bc bc-unity3d",
          color: "#333",
          count: 0,
        },
        {
          name: "其他",
          category: "other",
          icon: "bc bc-other",
          color: "#e6e",
          count: 0,
        },
      ],
      blogList: [
        {
          id: "1",
          title: "回顾 2018，你都收获了什么？ 一起来总结你的 2018",
          tags: ["nodejs", "vuejs", "axios"],
          created_at: new Date(),
          category: "nodejs",
          author: "llq",
          content:
            "马上 2018 年就要过去了2018 年发生了很多很多的事情SegmentFault 也已经六岁了大家可以通过专栏文章添加标签“2018总结”发布我的 2018 年的总结社区小编会把优质文章汇总并发出小编忙完这几天也总结一下自己和 Se...333334343524",
          likeCount: 1,
          viewCount: 200,
        },
        {
          id: "2",
          title: "哈喽world1",
          tags: ["nodejs", "vuejs", "axios"],
          created_at: new Date(),
          category: "unity3d",
          author: "llq",
          content: "abcc,s,skk",
          likeCount: 1,
          viewCount: 192929,
        },
        {
          id: "3",
          title: "哈喽world2",
          tags: ["nodejs", "vuejs", "axios"],
          created_at: new Date(),
          // category: "mp",
          author: "llq",
          content: "abcc,s,skk",
          likeCount: 1938383,
          viewCount: 340,
        },
      ],
    };
  },
  computed: {
    // 计算文章数量
    categoryFormatList() {
      return this.categoryList.map((i) => {
        if (i.count == 0) {
          i.count = this.getCountByCategory(i.category);
        }
        return i;
      });
    },
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
    this.getBlogList();
    this.getTagList();
  },
  methods: {
    formatDate(date) {
      console.log(moment.locale());
      let m = moment(date);
      let now = moment();
      if (m.year() != now.year()) {
        return m.format("YYYY-MM-DD");
      } else if (m.month() != now.month()) {
        return m.format("MM月DD日");
      } else if (m.day() != now.day()) {
        return m.format("MM月DD日");
      }
      return moment(date).fromNow();
    },
    getBlogList() {
      this.axios.get(host + "/note/list").then((response) => {
        this.blogList = response.data.data;
        console.log(this.blogList);
        console.log("加载文章列表 success");
        // this.$notify({
        //   title: "加载文章列表",
        //   message: ""
        // });
      });
    },
    // 根据名称搜索
    searchBlogList() {
      this.axios.get(host + "/note/search").then((response) => {
        this.blogList = response.data.data;
        console.log(this.blogList);
        console.log("加载文章列表 success");
      });
    },
    // 通过标签获取文章列表（分页）
    getBlogByTag(tag) {
      // console.log("get blog by tag::");
      if (this.currentTag == tag) {
        this.currentTag = "";
        this.getBlogList();
        return;
      }
      this.currentTag = tag;
      this.axios
        .get(host + "/note/page/tag", {
          params: {
            tag: tag,
            page_no: 1,
            page_size: 10,
          },
        })
        .then((response) => {
          console.log(response);
          this.blogList = response.data.list;
          console.log(this.blogList);
          console.log("加载文章列表 success");
          // this.$notify({
          //   title: "加载文章列表",
          //   message: ""
          // });
        });
    },
    // 获取指定分类下文章的数量
    getCountByCategory(category) {
      let count = 0;
      for (let i in this.blogList) {
        if (this.blogList[i].category == category) {
          count++;
        }
        if (category == "other" && !this.blogList[i].category) {
          count++;
        }
      }
      return count;
    },
    // 创建空白文章
    createBlog() {
      this.axios.post(host + "/note/create").then((response) => {
        let note = response.data.data;
        console.log("创建文章 success", note);
        this.$router.push(
          `/note/detail/${note._id}/edit`,
          () => {},
          () => {},
        );
      });
    },
    // 获取标签列表
    getTagList() {
      this.axios.get(`${host}/note/tag/list`).then((response) => {
        this.tagList = response.data.data;
      });
    },
    // 跳转页面
    gotoNoteTag() {
      this.$router.push("/note/tag");
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes select_item {
  0% {
    border-left: 0px solid #fff0;
    border-right: 6px solid #fff0;
  }
  50% {
    border-left: 6px solid #fff0;
    border-right: 0px solid #fff0;
  }
  80% {
    border-left: 2px solid #fff0;
    border-right: 4px solid #fff0;
  }
  100% {
    border-left: 6px solid #fff0;
    border-right: 0px solid #fff0;
  }
}

.blog-list-link {
  border-radius: 4px;
  background-color: #fcfcfc;
  margin-bottom: 10px;
  padding: 0px 10px;
  cursor: pointer;
  border-left: 0px solid #fff0;
  border-right: 6px solid #fff0;
  line-height: 1.5;
  transition: all 0.2s;

  &:hover {
    animation: select_item 0.2s;
    border-left: 6px solid #fff0;
    border-right: 0px solid #fff0;
  }
}
.blog-list-title {
  font-size: 1.125rem;
  color: #333;
  font-weight: bold;
  padding: 10px 0px;
}
.blog-short-content {
  font-size: 0.8rem;
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0px;
}

.view-count,
.like-count,
.created-date {
  color: #aaa;
  font-size: 0.8rem;
  height: 16px;
  line-height: 16px;
}

.like-count {
  // font-weight: bold;
  cursor: pointer;
  transition: color 0.2s;

  .bc-like-fill {
    transition: color 0.2s;
    color: fadeout(#0a9, 20%);
    border-radius: 50%;
    padding-right: 0.2rem;
  }
  &:hover {
    color: #0a9;
    .bc-like-fill {
      color: #0a9;
    }
  }
}

.category-list {
  // background-color: #eee;
  // border-radius: 3px;
  font-size: 0.8rem;
  margin-bottom: 20px;
}

.category {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  color: #666;
  transition: all 0.3s;
  filter: grayscale(1);
  &:hover {
    background-color: #eee;
    filter: grayscale(0);
  }
  &:active {
    background-color: #ddd;
  }
}

.category-active {
  background-color: #def;
}

.item-bottom-tag {
  min-width: 80px;
  width: fit-content;
}
</style>
